import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '26, 35, 126',
		'primary-dark': '0, 0, 81',
		'accent': '227, 79, 0',
		'accent-plus': '255, 255, 255',
	},
});
